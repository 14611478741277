import React from 'react';
import { LoadingOutlined } from '@ant-design/icons';
import { Spin } from 'antd';

const LoadingMessage = () => {
  const loadingIcon = <LoadingOutlined style={{ fontSize: 24 }} spin />;
  return (
    <div className="animated fadeIn pt-3 text-center">
      <Spin indicator={loadingIcon} tip="Loading..." />
    </div>
  );
};

export default LoadingMessage;
