import './wdyr';
import React from 'react';
import ReactDOM from 'react-dom';
import App from 'components/App';
import * as Sentry from '@sentry/browser';
import * as serviceWorker from 'serviceWorker';

import { LoadingMessage } from 'components/Elements';

const dom = (
  <React.Suspense fallback={LoadingMessage()}>
    <App />
  </React.Suspense>
);

Sentry.init({
  dsn: 'https://e69183c2ddd44dda80f1f68b4eba9c0f@sentry.io/1762915',
});

ReactDOM.render(dom, document.getElementById('root'));

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
