import React from 'react';
import { BrowserRouter, Route, Switch } from 'react-router-dom';
import './App.scss';
import Amplify from '@aws-amplify/core';
import aws_exports from 'aws-exports';
import { useParams } from 'react-router';
import amplitude from 'amplitude-js';

const appsync = {
  aws_appsync_graphqlEndpoint:
    'https://5w6kbhbm5fflxppg7j4c7so7ke.appsync-api.us-west-2.amazonaws.com/graphql',
  aws_appsync_region: 'us-west-2',
  aws_appsync_authenticationType: 'API_KEY',
  aws_appsync_apiKey: 'da2-wz6ywl5itbeldn46bg37hd2ciy',
};

Amplify.configure({ ...aws_exports, ...appsync });

amplitude.getInstance().init(process.env.REACT_APP_AMPLITUDE_KEY);

const Layout = React.lazy(() => import('components/Layout'));
const WebView = React.lazy(() => import('components/WebView'));

function User() {
  const { id } = useParams();
  return <WebView {...id} />;
}

const App = () => {
  return (
    <BrowserRouter>
      <Switch>
        <Route path="/client=:id" children={<User />} />
        <Route path="/" name="Home" render={props => <Layout {...props} />} />
      </Switch>
    </BrowserRouter>
  );
};

export default App;
